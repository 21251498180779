import { useMutation } from "@apollo/client";
import { ExecutionResult } from "graphql";
import React, { ChangeEvent, FC, useCallback, useState, useMemo } from "react";
import { UserRoles } from "../../../api/interface/me";
import { CreateUserMutationResult, CreateUserMutationVariables } from "../../../api/interface/users";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import CREATE_USER from "../../../api/mutation/users/createUser";
import Input from "../../../shared/elements/input/Input";
import Select from "../../../shared/elements/select/Select"
import ModalComponent from "../../../shared/elements/modal/modal";
import { useToast } from "../../../shared/helpers/useToast";
import { ModalChildrenWrapper } from "../../devices";
import { UserFields } from "../index";
import { CreateUserSchema } from "../validation";

export type DeviceModalProps = {
  visible: boolean;
  title: string;
  onClose(): void;
  refetch(): void;
  initialValue: UserFields;
};

const roleOptions = [
  {label: 'Utilisateur', value: 'USER'},
  {label: 'Admin', value: 'ADMIN'}
];

const DefaultWrapper = styled.div`
  padding-bottom: 10px;
`;

const DefaultInputWrapper = styled.div`
  width: 323px;
  margin-bottom: 10px;
  ${media.md`
		width: 411px;
	`};
`;

const ModalInput = styled(Input)`
  margin-top: 10px;
`;

const CreateUserModal: FC<DeviceModalProps> = (
  {
    visible,
    title,
    refetch,
    onClose,
    initialValue
  }
) => {
  const [userFields, setUserFields] = useState<UserFields>({ ...initialValue });

  const inputOnChange = useCallback(
    (field) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      return setUserFields((prev) => ({
        ...prev,
        [field]: value,
      }));
    }, []);

  const selectOnChange = useCallback(
    (field) => (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      return setUserFields((prev) => ({
        ...prev,
        [field]: value,
      }));
    }, []);

  const resetUserFields: UserFields = {
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    role: UserRoles.USER,
  };

  const resetDeviceFieldsAction = useCallback(() => setUserFields({ ...resetUserFields }), [resetUserFields]);

	const onCloseModal = useCallback(() => {
    onClose();
    resetDeviceFieldsAction();
  }, [onClose, resetDeviceFieldsAction]);

  const { showToast, portalComponent: toast } = useToast();

  const [createDeviceMutation] = useMutation<CreateUserMutationResult, CreateUserMutationVariables>(CREATE_USER);

  const enableButton = useMemo(() => userFields.username && userFields.firstName && userFields.lastName && userFields.password, [userFields]);

  const handleCreateDevice = useCallback(async () => {
    const validationPassed = await CreateUserSchema.validate({ ...userFields }, { abortEarly: false }).catch((e) => {
      showToast({
        appearance: {
          duration: 3000,
          color: "lightRed",
          borderColor: "borderRedColor",
          titleColor: "darkRed",
          message: e.inner[0].message,
        },
      });
    });

    if (validationPassed && enableButton) {
      return createDeviceMutation({ variables: { input: { ...userFields } } })
        .then(async ({ data }: ExecutionResult<CreateUserMutationResult>) => {
          if (data?.createUser) {
            showToast({
              appearance: {
                duration: 3000,
                color: "green",
                borderColor: "borderRedColor",
                titleColor: "darkRed",
                message: `Utilisateur créé avec succès ${data.createUser.firstName} ${data.createUser.lastName}.`,
              },
            });
            resetDeviceFieldsAction();
            onClose();
            return refetch();
          }
        })
        .catch((e) => {
          showToast({
            appearance: {
              duration: 3000,
              color: "lightRed",
              borderColor: "borderRedColor",
              titleColor: "darkRed",
              message: e.message,
            },
          });
        });
    }
  }, [showToast, refetch, onClose, createDeviceMutation, userFields, resetDeviceFieldsAction, enableButton]);

  return (
    <>
      {toast}
      <ModalComponent title={title} buttonText="créer" visible={visible} onApprove={handleCreateDevice} onReject={onCloseModal} disabled={!enableButton}>
        <ModalChildrenWrapper>
          <DefaultWrapper>
            <DefaultInputWrapper>
              <ModalInput
                placeholder="nom d'utilisateur"
                value={userFields.username}
                onChange={inputOnChange("username")}
                label="Nom d'utilisateur"
              />
            </DefaultInputWrapper>
            <DefaultInputWrapper>
              <ModalInput
                placeholder="le prénom"
                value={userFields.firstName}
                onChange={inputOnChange("firstName")}
                label="le prénom"
              />
            </DefaultInputWrapper>
            <DefaultInputWrapper>
              <ModalInput
                placeholder="le nom"
                value={userFields.lastName}
                onChange={inputOnChange("lastName")}
                label="le nom"
              />
            </DefaultInputWrapper>
            <DefaultInputWrapper>
              <Select
                value={userFields.role}
                options={roleOptions}
                onChange={selectOnChange("role")}
                label="Rôle"
              />
            </DefaultInputWrapper>
            <DefaultInputWrapper>
              <ModalInput
                placeholder="mot de passe"
                value={userFields.password}
                onChange={inputOnChange("password")}
                label="Mot de passe"
              />
            </DefaultInputWrapper>

            {/* TODO: add temporary help text: Role variants: USER | ADMIN. */}
          </DefaultWrapper>
        </ModalChildrenWrapper>
      </ModalComponent>
    </>
  );
};

export default CreateUserModal;
