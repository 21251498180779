import { useMutation } from "@apollo/client";
import { ExecutionResult } from "graphql";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import React, { ChangeEvent, FC, useCallback, useEffect, useState, useMemo } from "react";
import { UserRoles } from "../../../api/interface/me";
import { UpdateUserMutationResult, UpdateUserMutationVariables } from "../../../api/interface/users";
import UPDATE_USER from "../../../api/mutation/users/updateUser";
import Input from "../../../shared/elements/input/Input";
import Select from "../../../shared/elements/select/Select"
import ModalComponent from "../../../shared/elements/modal/modal";
import { useToast } from "../../../shared/helpers/useToast";
import { ModalChildrenWrapper } from "../../devices";
import { UserFields } from "../index";
import { UpdateUserSchema } from "../validation";

export type UserModalProps = {
  visible: boolean;
  title: string;
  onClose(): void;
  refetch(): void;
  initialValue: UserFields;
  userId: number;
};

const roleOptions = [
  {label: 'Utilisateur', value: 'USER'},
  {label: 'Admin', value: 'ADMIN'}
];


const DefaultWrapper = styled.div`
  padding-bottom: 10px;
`;

const DefaultInputWrapper = styled.div`
  width: 323px;
  margin-bottom: 10px;
  ${media.md`
		width: 411px;
	`};
`;

const ModalInput = styled(Input)`
  margin-top: 10px;
`;

const UpdateUserModal: FC<UserModalProps> = (
  {
    visible,
    title,
    refetch,
    onClose,
    initialValue,
    userId,
  },
) => {
  const [userFields, setUserFields] = useState<UserFields>({ ...initialValue });

  useEffect(() => setUserFields({ ...initialValue }), [initialValue]);

  const inputOnChange = useCallback((field) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    return setUserFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  const selectOnChange = useCallback(
    (field) => (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      return setUserFields((prev) => ({
        ...prev,
        [field]: value,
      }));
    }, []);

  const resetUserFields: UserFields = {
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    role: UserRoles.USER,
  };

  const resetDeviceFieldsAction = useCallback(() => setUserFields({ ...resetUserFields }), [resetUserFields]);

  const { showToast, portalComponent: toast } = useToast();

  const [updateDeviceMutation] = useMutation<UpdateUserMutationResult, UpdateUserMutationVariables>(UPDATE_USER);

  const enableButton = useMemo(() => userFields.username && userFields.firstName && userFields.lastName, [userFields]);


  const handleCreateDevice = useCallback(async () => {
    const validationPassed = await UpdateUserSchema.validate({ ...userFields }, { abortEarly: false })
    .catch((e) => {
      showToast({
        appearance: {
          duration: 3000,
          color: "lightRed",
          borderColor: "borderRedColor",
          titleColor: "darkRed",
          message: e.inner[0].message,
        },
      });
    });

    if (validationPassed && enableButton) {
      return updateDeviceMutation({ variables: { id: userId, input: { ...userFields } } })
      .then(async ({ data }: ExecutionResult<UpdateUserMutationResult>) => {
        if (data?.updateUser) {
          showToast({
            appearance: {
              duration: 3000,
              color: "green",
              borderColor: "borderRedColor",
              titleColor: "darkRed",
              message: `Utilisateur mis à jour avec succès ${data.updateUser.firstName} ${data.updateUser.lastName}.`,
            },
          });
          resetDeviceFieldsAction();
          onClose();
          return refetch();
        }
      })
      .catch((e) => {
        showToast({
          appearance: {
            duration: 3000,
            color: "lightRed",
            borderColor: "borderRedColor",
            titleColor: "darkRed",
            message: e.message,
          },
        });
      });
    }
  }, [showToast, refetch, onClose, updateDeviceMutation, userFields, userId, resetDeviceFieldsAction, enableButton]);

  return (
    <>
      {toast}
      <ModalComponent
        title={title}
				visible={visible}
				buttonText="modifier"
        onApprove={handleCreateDevice}
        onReject={onClose}
        disabled={!enableButton}
      >
        <ModalChildrenWrapper>
          <DefaultWrapper>
            <DefaultInputWrapper>
              <ModalInput
                placeholder="nom d'utilisateur"
                value={userFields.username}
                onChange={inputOnChange("username")}
                label="Nom d'utilisateur"
              />
            </DefaultInputWrapper>
            <DefaultInputWrapper>
              <ModalInput
                placeholder="le prénom"
                value={userFields.firstName}
                onChange={inputOnChange("firstName")}
                label="le prénom"
              />
            </DefaultInputWrapper>
            <DefaultInputWrapper>
              <ModalInput
                placeholder="le nom"
                value={userFields.lastName}
                onChange={inputOnChange("lastName")}
                label="le nom"
              />
            </DefaultInputWrapper>
            <DefaultInputWrapper>
              <Select
                value={userFields.role}
                options={roleOptions}
                onChange={selectOnChange("role")}
                label="Rôle"
              />
            </DefaultInputWrapper>
            <DefaultInputWrapper>
              <ModalInput
                placeholder="mot de passe"
                value={userFields.password}
                onChange={inputOnChange("password")}
                label="Mot de passe"
              />
            </DefaultInputWrapper>

            {/* TODO: add temporary help text: Role variants: USER | ADMIN. */}
          </DefaultWrapper>
        </ModalChildrenWrapper>
      </ModalComponent>
    </>
  );
};

export default UpdateUserModal;
