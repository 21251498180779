import * as yup from "yup";

export const firstName = yup.string().required("Field firstName is required.").min(1).max(64, `Le champ 'nom' ne doit pas dépasser 64 caractères.`);
export const lastName = yup.string().required("Field lastName is required.").min(1).max(64, `Le champ 'prénom' ne doit pas dépasser 64 caractères.`);
export const username = yup.string().required("Field username is required.").min(1).max(64, `Le champ 'login' ne doit pas dépasser 64 caractères.`);
export const role = yup.string().required("Field role is required.");
export const password = yup.string().required("Field password is required.");

export const CreateUserSchema = yup.object({ firstName, lastName, username, role, password });

export const UpdateUserSchema = yup.object({ firstName, lastName, username, role });
