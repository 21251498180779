export enum DeviceStatus {
	Active = "Active",
	Inactive = "Inactive",
}

export type Device = {
	id: number;
	reference: string;
	mac: string;
	status: DeviceStatus;
	name: string;
}

export type GetDevicesQueryResult = {
  devices: {
    limit: number;
    offset: number;
    count: number;
    values : Device[]
  };
}

export type Filter = {
  limit: number;
  offset: number | undefined;
  search: string;
}

export type RemoveDeviceMutationResult = {
  removeDevice: Device;
}

export type RemoveDeviceMutationVariables = {
  id: number;
}

export type CreateDeviceMutationResult = {
  createDevice: Device;
}

export type CreateDeviceMutationVariables = {
  input: {
    reference: string;
    mac: string;
    name: string;
  };
}

export type UpdateDeviceMutationResult = {
  updateDevice: Device;
}

export type UpdateDeviceMutationVariables = {
  id: number;
  input: {
    reference: string;
    name: string;
  };
}

export type ActivateDeviceMutationResult = {
  activateDevice: Device;
}

export type ActivateDeviceMutationVariables = {
  id: number;
}

export type DeactivateDeviceMutationResult = {
  deactivateDevice: Device;
}

export type DeactivateDeviceMutationVariables = {
  id: number;
}
