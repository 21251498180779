import gql from "graphql-tag";

const UPDATE_DEVICE = gql`
  mutation updateDevice($id: ID!, $input: DeviceUpdateInput!) {
    updateDevice(id: $id, input: $input) {
      id
      mac
      reference
			status
			name
    }
  }
`;

export default UPDATE_DEVICE;
