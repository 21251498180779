import gql from "graphql-tag";

const GET_USERS = gql`
  query users($filter: Filter) {
    users(filter: $filter) {
      count
      limit
      offset
      values {
        id
        firstName
        lastName
        username
        role
      }
    }
  }
`;

export default GET_USERS;
