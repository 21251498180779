import React from "react";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../../../config/theme/index";

type MenuButtonProps = {
  onClick(): void;
};

const MenuButtonWrapper = styled.button<MenuButtonProps>`
  position: absolute;
  right: 24px;
  top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 40px;
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.gray};
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  ${media.lg`
		display: none;
	`};
`;
const LineSpan = styled.div`
  width: 24px;
  height: 2px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 1px;
`;
const FirstLineSpan = styled(LineSpan)`
  margin-bottom: 6px;
`;
const LastLineSpan = styled(LineSpan)`
  margin-top: 6px;
`;
const MenuButton = (props: MenuButtonProps) => {
  return (
    <MenuButtonWrapper onClick={props.onClick}>
      <FirstLineSpan />
      <LineSpan />
      <LastLineSpan />
    </MenuButtonWrapper>
  );
};

export default MenuButton;
