import ReactDOM from "react-dom";
import React, { useState, useCallback } from "react";
import ModalComponent from "../elements/modal/modal";

type UseDialogHookReturnType = {
  dialog: React.ReactPortal,
  showDialog: (config: DialogConfigType) => void,
  hideDialog: () => void,
};

type DialogConfigType = {
  title: string;
  extraField?: string;
  buttonText: string;
  children?: any;
  onApprove(args?: any): void;
  onReject(): void;
};

export function useDialog(state: boolean): UseDialogHookReturnType {
  const [display, setDisplay] = useState<boolean>(state);

  const [dialog, setDialog] = useState<DialogConfigType>({
    title: "Are you confirming this action?",
    buttonText: "entrer",
    onApprove: () => console.log("Confirm"),
		onReject: () => console.log("Cancel"),
  });

  const showDialog = useCallback((config: DialogConfigType) => {
    setDisplay(true);
    setDialog({...config});
  }, []);

  const hideDialog = useCallback(() => setDisplay(false), []);

  const dialogComponent = (
    <ModalComponent
      title={dialog.title}
      extraField={dialog.extraField}
      buttonText={dialog.buttonText}
      visible={display}
      onApprove={dialog.onApprove}
      onReject={dialog.onReject}
      children={dialog.children}
    />
  );

  const portalRoot = document.getElementById("dialogContainer") as HTMLElement;

  const portalComponent = ReactDOM.createPortal(display ? dialogComponent : null, portalRoot);

  return { dialog: portalComponent, showDialog, hideDialog };
}
