import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../config/theme/index";
import { H6Style } from "../../../shared/typography/typography";
import { Container, Row, Col } from "styled-bootstrap-grid";

const ListTitleContainer = styled.div`
  display: none;
  padding: 42px 0 0 17px;
  margin-bottom: 30px;
  border-top: 1px solid
    ${(props: StyledThemePropsType) => props.theme.colors.gray};

  ${media.lg`
		display:flex;
	`};
`;

const TitleText = styled.h6`
  ${H6Style};
  margin: 0;
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkGray};
`;

const ListTitle = () => {
  return (
    <ListTitleContainer>
      <Container fluid>
        <Row>
          <Col col md={3}>
            <TitleText>Nom d'utilisateur</TitleText>
          </Col>
          <Col col md={3}>
            <TitleText>le prénom</TitleText>
          </Col>
          <Col col md={3}>
            <TitleText>le nom</TitleText>
          </Col>
          <Col col md={3}>
            <TitleText>Action</TitleText>
          </Col>
        </Row>
      </Container>
    </ListTitleContainer>
  );
};

export default ListTitle;
