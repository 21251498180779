import gql from "graphql-tag";

const ACTIVATE_DEVICE = gql`
  mutation activateDevice($id: ID!) {
    activateDevice(id: $id) {
      id
      mac
      reference
			status
			name
    }
  }
`;

export default ACTIVATE_DEVICE;
