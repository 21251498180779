import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

import LogoImage from "../../../../icons/logoZenConnect1.svg";
import LogoSquare from "../../../../icons/logoZenConnect2.svg";

const HeaderLogoWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 22px;
  display: flex;
  align-items: center;

  ${media.md`
		left: 24px;
	`};

  ${media.lg`
		left: auto;
	`};
`;
const Image = styled.img`
  margin-left: 30px;
`;

const HeaderLogo = () => {
  return (
    <HeaderLogoWrapper>
      <img src={LogoImage} alt="logo" />
      <Image src={LogoSquare} />
    </HeaderLogoWrapper>
  );
};

export default HeaderLogo;
