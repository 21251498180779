import React from "react";
import styled, { css } from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../config/theme/index";
import { H5Style } from "../../typography/typography";

import IconEdit from "../../icons/edit.svg";
import IconTrash from "../../icons/trash.svg";

type CustomButtonProps = {
  flagColor?: string;
  onClick(): void;
};

const CustomButton = styled.button<CustomButtonProps>`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 111px;
  height: 30px;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  margin-right: 10px;
  ${H5Style};
  ${(props: CustomButtonProps & StyledThemePropsType) => {
    switch (props.flagColor) {
      case "Modifier":
        return css`
          border: 1px solid
            ${(props: StyledThemePropsType) => props.theme.colors.blue};
          color: ${(props: StyledThemePropsType) => props.theme.colors.blue};
        `;
      case "Supprimer":
        return css`
          border: 1px solid
            ${(props: StyledThemePropsType) => props.theme.colors.red};
          color: ${(props: StyledThemePropsType) => props.theme.colors.red};
        `;
    }
  }};

  ${media.lg`
		margin-bottom: 0;
	`};
`;

const Image = styled.img`
  margin-right: 10px;
`;

type ButtonComponentProps = {
  name: string;
  onClick(): void;
}

function ButtonComponent({name, onClick} : ButtonComponentProps) {
	const imageSource = name === "Modifier" ? IconEdit : IconTrash;

  return (
    <CustomButton flagColor={name} onClick={onClick}>
      <Image src={imageSource } />
      {name}
    </CustomButton>
  );
}
export default ButtonComponent;
