import gql from "graphql-tag";

const CREATE_DEVICE = gql`
  mutation createDevice($input: DeviceInput!) {
    createDevice(input: $input) {
      id
      mac
      reference
			status
			name
    }
  }
`;

export default CREATE_DEVICE;
