import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../../../config/theme/index";
import { H5Style } from "../../../../typography/typography";

const UserWrapper = styled.div`
  display: flex;
	align-items: center;
	max-width: 250px;
`;
const UserIconWrapper = styled.div`

`;
const UserIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  opacity: 0.48;
  margin-right: 10px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.pink};
  color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  ${media.lg`
		margin-right: 19px;
	`};
`;
const UserName = styled.h4`
  ${H5Style};
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

type UserSectionProps = {
  firstName: string;
  lastName: string;
};

const UserSection = ({ firstName, lastName }: UserSectionProps) => {
  const initialLetters = (firstName.charAt(0) + (lastName ? lastName.charAt(0) : "")).toUpperCase();

  return (
    <UserWrapper>
      <UserIconWrapper>
        <UserIcon>{initialLetters}</UserIcon>
      </UserIconWrapper>
      <UserName>{`${firstName} ${lastName}`}</UserName>
    </UserWrapper>
  );
};

export default UserSection;
