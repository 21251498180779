import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../../config/theme/index";
import { H6Style } from "../../../../shared/typography/typography";
import { Col } from "styled-bootstrap-grid";

const HiddenCard = styled(Col)`
  flex-direction: column;
  ${media.lg`
		display: none;		
`};
`;
const HiddenDeviceText = styled(Col)`
  ${H6Style};
  margin: 5px 0 0;
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkGray};
`;
const HiddenElement = styled(Col)`
  margin-bottom: 36px;
`;

const HiddenElementStatus = styled(HiddenElement)`
  margin-top: 44px;
`;
const HiddenElementAction = styled(HiddenElement)`
  margin-top: 46px;
`;

const HiddenCardItem = () => {
  return (
    <HiddenCard lg={3} md={5} col={5}>
      <HiddenElement col={12} sm={12}>
        <HiddenDeviceText>Numéro iPad</HiddenDeviceText>
      </HiddenElement>
			<HiddenElement col={12} sm={12}>
        <HiddenDeviceText>Nom d'iPad</HiddenDeviceText>
      </HiddenElement>
      <HiddenElement col={12} sm={12}>
        <HiddenDeviceText>Adresse MAC</HiddenDeviceText>
      </HiddenElement>
      <HiddenElementStatus col={10} md={10} sm={12}>
        <HiddenDeviceText>Etat de la réservation</HiddenDeviceText>
      </HiddenElementStatus>
      <HiddenElementAction col={12}>
        <HiddenDeviceText>Action</HiddenDeviceText>
      </HiddenElementAction>
    </HiddenCard>
  );
};

export default HiddenCardItem;
