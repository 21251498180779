import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../config/theme/index";
import ButtonComponent from "../button/ButtonComponent";

import { H3Style, H4 } from "../../typography/typography";
import Cross from "../../icons/cross.svg";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 25px;
  z-index: 1;
  background: rgba(46, 51, 56, 0.6);
  overflow-y: scroll;
`;
const Modal = styled.div`
  width: 355px;
  max-height: 680px;
  min-height: 222px;
  height: max-content;
  background: ${(props: StyledThemePropsType) => props.theme.colors.white};
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.borderColor};
  box-sizing: border-box;
  border-radius: 17px;

  ${media.md`
	width: 450px;
	`};
`;

const ModalHeader = styled.div`
  padding: 20px 16px 18px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.gray};

  ${media.md`
		padding: 20px 20px 18px;
	`};
`;
const Title = styled(H4)`
  margin: 0;
  color: ${(props: StyledThemePropsType) => props.theme.colors.almostBlack};
`;
const CrossButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  cursor: pointer;
`;

const ModalBody = styled.div`
  padding: 25px 20px 0;
  border-bottom: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.gray};
`;

const ModalFooter = styled.div`
  padding: 20px 19px 20px 0;
  display: flex;
  justify-content: flex-end;
`;
const ButtonWrapper = styled.div`
  width: 182px;
  display: flex;
  justify-content: space-between;
`;
const ExtraField = styled.div`
  ${H3Style};
  margin-bottom: 20px;
`;

type ModalComponentProps = {
  title: string;
  extraField?: string;
  buttonText: string;
  visible: boolean;
  children?: any;
  onApprove(args?: any): void;
  onReject(): void;
  disabled?: boolean;
};

function ModalComponent({
  title,
  extraField,
  buttonText,
  visible,
  onApprove,
  onReject,
  children,
  disabled,
}: ModalComponentProps) {
  return (
    <div>
      {visible ? (
        <Wrapper>
          <Modal>
            <ModalHeader>
              <Title>{title}</Title>
              <CrossButton onClick={onReject}>
                <img src={Cross} alt="cross" />
              </CrossButton>
            </ModalHeader>
            <ModalBody>
              {extraField ? <ExtraField>{extraField}</ExtraField> : null}
              {children}
            </ModalBody>
            <ModalFooter>
              <ButtonWrapper>
                <ButtonComponent onClick={onReject} name="annuler" />
                <ButtonComponent onClick={onApprove} name={buttonText} disabled={disabled} />
              </ButtonWrapper>
            </ModalFooter>
          </Modal>
        </Wrapper>
      ) : null}
    </div>
  );
}

export default ModalComponent;
