import ReactDOM from "react-dom";
import React, { useCallback, useState } from "react";
import { DefinedColors } from "../../config/theme/index";

import AlertComponent from "../elements/toast/Alert";

type ToastAppearance = {
	color: DefinedColors;
	borderColor: DefinedColors;
	titleColor: DefinedColors;
  message: string;
  duration?: number;
};

type ToastConfig = {
  appearance: ToastAppearance;
  onTimeout?(): void;
  onTryAgain?(): void;
};

const initToastValues: ToastAppearance = {
  color: "red",
  borderColor: "red",
  titleColor: "red",
  message: "",
  duration: 3000,
};

/**
 * type UseToastHookReturnType = [(config: ToastConfig) => void, React.ReactPortal, () => void];
 */

type UseToastHookReturnType = {
  showToast: (config: ToastConfig) => void;
  portalComponent: React.ReactPortal;
  hideToast: () => void;
};

export function useToast(): UseToastHookReturnType {
  const [toast, setToast] = useState<ToastConfig>({
    appearance: { ...initToastValues },
  });
  const [display, setDisplay] = useState<boolean>(false);
  const [timeout, setTimeoutID] = useState<number>(0);

  const hideToast = useCallback(() => setDisplay(false), []);

  const showToast = useCallback(
    (config: ToastConfig) => {
      setDisplay(true);

      setToast({
        ...config,
        appearance: { ...initToastValues, ...config.appearance },
      });

      setTimeoutID(
        setTimeout(() => {
          hideToast();
          if (config.onTimeout) return config.onTimeout();
        }, toast.appearance.duration)
      );
    },
    [toast, hideToast]
  );

  const clearMessage = useCallback(() => {
    clearTimeout(timeout);
    hideToast();
    setTimeoutID(0);
  }, [hideToast, timeout]);

  const toastComponent = (
    <AlertComponent
			color={toast.appearance.color}
			borderColor={toast.appearance.borderColor}
			titleColor={toast.appearance.titleColor}
      message={toast.appearance.message}
      onClick={clearMessage}
    />
  );

  const portalRoot = document.getElementById("toastContainer") as HTMLElement;

  const portalComponent = ReactDOM.createPortal(
    display ? toastComponent : null,
    portalRoot
  );

  return { showToast, portalComponent, hideToast };
}
