import React from "react";
import styled from "styled-components";
import { H3Style } from "../../typography/typography";

const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyText = styled.h3`
	${H3Style};
`;

type EmptyProps = {
  entityName: string;
};

function Empty({ entityName }: EmptyProps) {
  return (
    <EmptyWrapper>
      <EmptyText>
			La liste des {entityName} est vide </EmptyText>
    </EmptyWrapper>
  );
}

export default Empty;
