import gql from "graphql-tag";

const GET_DEVICES = gql`
	query devices($filter: Filter) {
		devices(filter: $filter) {
			limit
			offset
			count
			values {
				id
				reference
				mac
				status
				name
			}
		}
	}
`;

export default GET_DEVICES;
