import React from "react";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { DefinedColors } from "../../../config/theme/index";
import { Alert } from "styled-alert-component";
import Icon from "../../icons/cross.svg";
import { H3Style } from "../../typography/typography";

type AlertTitle = {
  titleColor: DefinedColors;
};
type AlertWrapper = {
  color: DefinedColors;
  borderColor: DefinedColors;
};
const Wrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  z-index: 100;
  margin: 0 auto;
`;
const DefaultAlert = styled(Alert)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: StyledThemePropsType & AlertComponentProps) => props.theme.colors[props.color]};
  border: 1px solid ${(props: StyledThemePropsType & AlertComponentProps) => props.theme.colors[props.borderColor]};
`;
const DefaultButton = styled.button`
	position: absolute;
	right: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
const DefaultTitle = styled.h3<AlertTitle>`
  ${H3Style};
  color: ${(props: StyledThemePropsType & AlertTitle) => props.theme.colors[props.titleColor]};
  margin: 0;
`;

type AlertComponentProps = AlertTitle &
  AlertWrapper & {
    message: string;
    onClick(): void;
  };

function AlertComponent({ color, borderColor, titleColor, message, onClick }: AlertComponentProps) {
  return (
    <Wrapper>
      <DefaultAlert color={color} borderColor={borderColor}>
        <DefaultTitle titleColor={titleColor}>{message}</DefaultTitle>
        <DefaultButton type="button" onClick={onClick}>
          <img src={Icon} alt="close icon" />
        </DefaultButton>
      </DefaultAlert>
    </Wrapper>
  );
}

export default AlertComponent;
