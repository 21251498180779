import { Col, media, Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import { DeviceStatus } from "../../../api/interface/devices";
import { DefinedColors, StyledThemePropsType } from "../../../config/theme/index";
import { H3Style, H5Style } from "../../../shared/typography/typography";

export const Wrapper = styled.div`
  display: flex;
`;

export const DeviceCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${media.lg`
		flex-direction: column;
	`};
`;

export const Card = styled(Col)`
  display: flex;
  height: 370px;
  margin-bottom: 4px;
  justify-content: center;
  ${media.md`
	margin-bottom: 30px;
`};
  ${media.lg`
		height: 68px;
		margin-bottom: 4px;
	`};
`;

export const CardBody = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 345px;
  border-radius: 17px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.white};
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.borderColor};
  box-sizing: border-box;
  padding: 24px 17px;

  ${media.lg`
		padding: 0 0 0 17px;
		width: 100%;
	`};
`;

export const CardItem = styled(Col)`
  flex-direction: column;

  ${media.lg`
	display: flex;
	flex-direction: row;
`};
`;

export const Element = styled(Col)`
  margin-bottom: 29px;
  display: flex;
  ${media.lg`
	margin-bottom: 40px;
	`};
  ${media.lg`
	margin-bottom: 0;
	align-items: center;
	`};
`;

export const ElementAction = styled(Element)`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  ${media.lg`
		flex-direction: row;
		margin: 0;
	`};
`;

export const ElementSwitch = styled(Element)`
  margin-top: 43px;
  ${media.lg`
		margin: 0;
	`};
`;

export const DeviceText = styled.h3`
  ${H3Style};
  margin: 0;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

export const SwitchButton = styled.div`
  display: flex;
  width: 158px;
  height: 30px;
  border-radius: 8px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.gray};
	${media.xl`
		margin-left: 40px;
	`};
`;

export type ButtonProps = {
  name: string;
  activeColor: DefinedColors;
  type: DeviceStatus;
  onClick(args?: any): void;
  isActive: boolean;
};

type DefaultButtonProps = {
  key: number;
  onClick(args?: any): void;
  activeColor: DefinedColors;
  isActive: boolean;
};

export const DefaultButton = styled.button<DefaultButtonProps>`
  ${H5Style};
  color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  font-weight: 400;
  width: 79px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  outline: none;
  &:first-child {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  &:last-child {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  pointer-events: ${(props: DefaultButtonProps) => (props.isActive ? "none" : "all")};
  cursor: ${(props: DefaultButtonProps) => (props.isActive ? "none" : "pointer")};
  background-color: ${(props: StyledThemePropsType & DefaultButtonProps) => {
    return props.isActive ? props.theme.colors[props.activeColor] : props.theme.colors.gray;
  }};
  border-radius: 8px;
  &:hover {
    border: none;
    background-color: ${(props: StyledThemePropsType & DefaultButtonProps) => {
      return props.isActive ? props.theme.colors.gray : props.theme.colors[props.activeColor];
    }};
  }
`;
