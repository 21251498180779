import { useMutation, useQuery } from "@apollo/client";
import { ExecutionResult } from "graphql";
import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { GetMeQueryResult } from "../../api/interface/me";
import { SignInMutationResult, SignInMutationVariables } from "../../api/interface/signIn";
import { UserRoles } from "../../api/interface/users";
import SIGN_IN from "../../api/mutation/auth/signIn";
import GET_ME from "../../api/query/getMe";
import { StyledThemePropsType } from "../../config/theme/index";
import { AUTH_TOKEN } from "../../routing/variables";
import ButtonComponent from "../../shared/elements/button/ButtonComponent";
import Input from "../../shared/elements/input/Input";
import { useToast } from "../../shared/helpers/useToast";
import { H2 } from "../../shared/typography/typography";
import { UserContext } from "../../store/index";

const Container = styled.div`
  height: 100%;
  display: flex;
	justify-content: center;
	align-items: center;
`;

const LoginSection = styled.div`
  width: 345px;
  height: 292px;
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.borderColor};
  box-sizing: border-box;
  border-radius: 17px;
  padding: 43px 35px 0 40px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.white};
	margin-top: -73px;
  ${media.lg`
		width: 370px;
		padding: 45px 50px 0 49px;
	`};
`;

const Title = styled(H2)`
  letter-spacing: 1.2px;
  margin: 0 0 30px 0;
  color: ${(props: StyledThemePropsType) => props.theme.colors.almostBlack};
`;

export const InputWrapper = styled.div`
  width: 270px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

type LoginType = {
  login: string;
  password: string;
};

const LoginContainer = () => {
  const { showToast, portalComponent: toast } = useToast();

  const { push } = useHistory();

  const [signInMutation] = useMutation<SignInMutationResult, SignInMutationVariables>(SIGN_IN);

  const { data, loading, refetch } = useQuery<GetMeQueryResult>(GET_ME, { fetchPolicy: "network-only" });

  const { saveUserInfo } = useContext(UserContext);

  useEffect(() => {
    if (!loading && data) {
      saveUserInfo({
        userRole: data.me.role,
        isAuthorized: true,
        isAdmin: data.me.role === UserRoles.ADMIN,
      });
    }
  }, [loading, data, saveUserInfo]);

  const initValue: LoginType = { login: "", password: "" };

  const [val, setVal] = useState(initValue);

  const inputOnChange = useCallback(
    (field) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      return setVal((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    []
  );

  const loginProcess = useCallback(async () => {
    return signInMutation({
      variables: {
        login: val.login,
        password: val.password,
      },
    })
      .then(async ({ data }: ExecutionResult<SignInMutationResult>) => {
        if (data) {
          localStorage.setItem(AUTH_TOKEN, data.signIn);
          setVal({ ...initValue });
          await refetch();
          push("/devices");
        }
      })
      .catch((error) => {
        showToast({
          appearance: {
            color: "lightRed",
            borderColor: "borderRedColor",
            titleColor: "darkRed",
            message: error.message,
            duration: 3000,
          },
        });
      });
  }, [showToast, val, signInMutation, push, setVal, initValue, refetch]);

  return (
    <Container>
      {toast}
      <LoginSection>
        <Title>Bienvenue !</Title>
        <form
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          }}
        >
          <InputWrapper>
            <Input placeholder="nom d'utilisateur" value={val.login} onChange={inputOnChange("login")} />
          </InputWrapper>
          <InputWrapper>
            <Input placeholder="mot de passe" type="password" value={val.password} onChange={inputOnChange("password")} />
          </InputWrapper>
          <ButtonWrapper>
            <ButtonComponent
              name="connexion"
              onClick={loginProcess}
              disabled={ !(val.login.length >= 1 && val.password.length >=1) }
            />
          </ButtonWrapper>
        </form>
      </LoginSection>
    </Container>
  );
};

export default LoginContainer;
