import React, { useContext } from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../config/theme/index";
import { H6Style } from "../../../shared/typography/typography";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { UserContext } from "../../../store/index";

const ListTitleContainer = styled.div`
  display: none;
  padding: 42px 0 0 17px;
  margin-bottom: 30px;
  border-top: 1px solid
    ${(props: StyledThemePropsType) => props.theme.colors.gray};

  ${media.lg`
		display:flex;
	`};
`;

const TitleText = styled.h6`
  ${H6Style};
  margin: 0;
  color: ${(props: StyledThemePropsType) => props.theme.colors.darkGray};
`;
const TitleTextSpecial = styled(TitleText)`
	margin-left: 40px;
`;

const ListTitle = () => {
  const { isAdmin } = useContext(UserContext);

  return (
    <ListTitleContainer>
      <Container fluid>
        <Row>
          <Col col md={2}>
            <TitleText>Numéro iPad</TitleText>
          </Col>
					<Col col md={2}>
            <TitleText>Nom d'iPad</TitleText>
          </Col>
          <Col col md={2}>
            <TitleText>Adresse MAC</TitleText>
          </Col>
          <Col col md={3}>
            <TitleTextSpecial>Etat de la réservation</TitleTextSpecial>
          </Col>
          {isAdmin ?
            <Col col md={2}>
              <TitleText>Action</TitleText>
            </Col>
            : null
          }
        </Row>
      </Container>
    </ListTitleContainer>
  );
};

export default ListTitle;
