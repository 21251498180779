import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { InputTextStyle } from "../../typography/typography";

type DefaultInputProps = {
  placeholder: string;
  label?: string;
  value: string;
  type?: string;
  disabled?: boolean;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
};

const DefaultInput = styled.input<DefaultInputProps>`
  ${InputTextStyle};
  width: 100%;
  height: 38px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.white};
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.blueGray};
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 11px;
  outline: none;
  ::-webkit-input-placeholder {
    color: ${(props: StyledThemePropsType) => props.theme.colors.inputText};
  }
  margin-top: 10px;
`;

const Ladel = styled.label`
  ${InputTextStyle};
  margin-bottom: 10px;
`;

function Input({ value, label, onChange, placeholder, type, disabled }: DefaultInputProps) {
  return (
    <>
      {label ? <Ladel>{label}</Ladel> : null}
      <DefaultInput type={type} placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} />
    </>
  );
}

export default Input;
