import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import { GridThemeProvider } from "styled-bootstrap-grid";
import { ThemeProvider } from "styled-components";
import client from "./api/client";
import gridTheme from "./config/theme/grid";
import defaultTheme from "./config/theme/index";
import "./index.css";
import Root from "./routing/index";
import Store from "./store/index";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={defaultTheme}>
        <GridThemeProvider gridTheme={gridTheme}>
          <Store>
            <Root />
          </Store>
        </GridThemeProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
