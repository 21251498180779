import { User } from "./users";

export enum UserRoles {
	USER = "USER",
	ADMIN = "ADMIN",
}

export type GetMeQueryResult = {
	me: User
}
