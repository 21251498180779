import React from "react";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../../../config/theme/index";
import { H6Style } from "../../../../typography/typography";

import Icon from "../../../../icons/plus-circle.svg";

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
const Image = styled.img`
  margin-right: 8px;
`;
const ButtonText = styled.h2`
  ${H6Style};
  color: ${(props: StyledThemePropsType) => props.theme.colors.blue};
  margin: 0;
`;

type AddButtonProps = {
  button: string;
  onClick(args?: any): void;
};

function AddButton(props: AddButtonProps) {
  return (
    <ButtonWrapper onClick={props.onClick}>
      <Image src={Icon} />
      <ButtonText> {props.button} </ButtonText>
    </ButtonWrapper>
  );
}

export default AddButton;
