import { useMutation, useQuery } from "@apollo/client";
import { ExecutionResult } from "graphql";
import React, { useCallback, useState } from "react";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { UserRoles } from "../../api/interface/me";
import {
  Filter,
  GetUsersQueryResult,
  RemoveUserMutationResult,
  RemoveUserMutationVariables,
  User,
} from "../../api/interface/users";
import REMOVE_USER from "../../api/mutation/users/removeUser";
import GET_USERS from "../../api/query/getUsers";
import ListHeader from "../../shared/elements/list-header/ListHeader";
import { useDialog } from "../../shared/helpers/useDialog";
import { useModal } from "../../shared/helpers/useModal";
import { useToast } from "../../shared/helpers/useToast";
import ListTitle from "./components/ListTitle";
import CreateUserModal from "./modals/createUserModal";
import UpdateUserModal from "./modals/updateUserModal";
import UsersContainer from "./view";
import Loader from "../../shared/elements/loader/Loader";
import Empty from "../../shared/elements/empty-message/Empty";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
const UserWrapper = styled.div`
  max-width: 1152px;
  margin: 0 auto;
  padding: 46px 15px 0;
  ${media.md`
		padding: 98px 24px 0;
	`};
  ${media.lg`
		padding-top: 117px;
	`};
`;

export type UserValues = {
  users: {
    limit: number;
    offset: number;
    count: number;
    values: User[];
  };
};

export type UserFields = {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: UserRoles;
};

const UserPage = () => {
  const { dialog, showDialog, hideDialog } = useDialog(false);

  const { showToast, portalComponent: toast } = useToast();

  const duration = 3000;

  const [search, setSearch] = useState<string>("");

  const filter: Filter = {
    search,
    limit: 1000,
    offset: 0,
  };

  const { data, loading, fetchMore, refetch } = useQuery<GetUsersQueryResult>(GET_USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter,
    },
  });

  const refetchUser = useCallback(() => refetch({ variables: { filter } }), [refetch, filter]);

  const [removeUserMutation] = useMutation<RemoveUserMutationResult, RemoveUserMutationVariables>(REMOVE_USER);

  const handleRemoveUser = useCallback(
    (id: number) => {
      return removeUserMutation({ variables: { id } })
        .then(async ({ data }: ExecutionResult<RemoveUserMutationResult>) => {
          if (data?.removeUser) {
            showToast({
              appearance: {
                duration,
                color: "green",
                borderColor: "borderRedColor",
                titleColor: "darkRed",
                message: `Suppression réussie`,
              },
            });
            hideDialog();
            return refetchUser();
          }
        })
        .catch((e) => {
          showToast({
            appearance: {
              duration,
              color: "lightRed",
              borderColor: "borderRedColor",
              titleColor: "darkRed",
              message: e.message,
            },
          });
          hideDialog();
        });
    },
    [showToast, refetchUser, removeUserMutation, hideDialog]
  );

  const removeUserProcess = useCallback(
    async (id: number, username: string) => {
      showDialog({
        title: `Suppression de l'utilisateur`, 
        extraField: `Êtes-vous certain de vouloir retirer l'utilisateur ${username} ?`,
        buttonText: "supprimer",
        onReject: () => hideDialog(),
        onApprove: () => handleRemoveUser(id),
      });
    },
    [handleRemoveUser, hideDialog, showDialog]
  );

  const increaseOffset = useCallback(() => {
    return fetchMore({
      variables: { filter: { limit: 30, search: "", offset: data?.users.values.length } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          users: {
            ...prev.users,
            values: [...prev.users.values, ...fetchMoreResult.users.values],
          },
        });
      },
    });
  }, [data, fetchMore]);

  const usersData: UserValues = data ? data : { users: { count: 0, limit: 0, offset: 0, values: [] } };

  const resetUserFields: UserFields = {
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    role: UserRoles.USER,
  };

  const [userFields, setUserFields] = useState<UserFields>({ ...resetUserFields });

  const [userId, setUserId] = useState<number>(0);

  const { visible: visibleCreateModal, showModal: showCreateModal, hideModal: hideCreateModal } = useModal();

  const createUserProcess = useCallback(() => showCreateModal(), [showCreateModal]);

  const { visible: visibleUpdateModal, showModal: showUpdateModal, hideModal: hideUpdateModal } = useModal();

  const updateUserProcess = useCallback(
    (id: number) => {
      setUserId(id);
      const user = data?.users.values.find((d) => d.id === id && d);
      const fields: UserFields = user
        ? {
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            password: "",
            role: user.role,
          }
        : { ...resetUserFields };
      setUserFields({ ...fields });
      showUpdateModal();
    },
    [showUpdateModal, resetUserFields, data]
  );
	const isNoResult: boolean = !loading && usersData.users.values.length === 0;

  return (
    <Wrapper>
      {toast}
      {dialog}
      <CreateUserModal
        visible={visibleCreateModal}
        title="Créer un personne"
        onClose={hideCreateModal}
        refetch={refetchUser}
        initialValue={userFields}
      />
      <UpdateUserModal
        visible={visibleUpdateModal}
        title="Modifier un personne"
        onClose={hideUpdateModal}
        refetch={refetchUser}
        initialValue={userFields}
        userId={userId}
      />
      <UserWrapper>
        <ListHeader
          title="Liste du personnel"
          button="Créer un personne"
          onClick={createUserProcess}
          onSearch={setSearch}
          search={search}
        />
        <ListTitle />
        {loading ? <Loader loadedItemName="l'utilisateur" /> : null}
        {isNoResult ? <Empty entityName="utilisateurs" /> : (
          <UsersContainer
            usersData={usersData}
            increaseOffset={increaseOffset}
            remove={removeUserProcess}
            update={updateUserProcess}
          />
        )}
      </UserWrapper>
    </Wrapper>
  );
};

export default UserPage;
