import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import DevicePage from "../pages/devices/index";
import LoginPage from "../pages/login/index";
import UserPage from "../pages/users/index";
import Header from "../shared/elements/header/Header";

export default function Root() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/login" component={LoginPage} />

        <Route path="/users" component={UserPage} />

        <Route path="/devices" component={DevicePage} />

        <Redirect exact from="/" to="/login" />
      </Switch>
    </Router>
  );
}
