import gql from "graphql-tag";

const DEACTIVATE_DEVICE = gql`
  mutation deactivateDevice($id: ID!) {
    deactivateDevice(id: $id) {
      id
      mac
      reference
			status
			name
    }
  }
`;

export default DEACTIVATE_DEVICE;
