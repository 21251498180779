import gql from "graphql-tag";

const CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      id
      firstName
      lastName
      username
      role
    }
  }
`;

export default CREATE_USER;
