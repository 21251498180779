import { useMutation, useQuery } from "@apollo/client";
import { ExecutionResult } from "graphql";
import React, { useCallback, useState } from "react";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import {
  ActivateDeviceMutationResult,
  ActivateDeviceMutationVariables,
  DeactivateDeviceMutationResult,
  DeactivateDeviceMutationVariables,
  Device,
  Filter,
  GetDevicesQueryResult,
  RemoveDeviceMutationResult,
  RemoveDeviceMutationVariables,
} from "../../api/interface/devices";
import ACTIVATE_DEVICE from "../../api/mutation/devices/activateDevice";
import DEACTIVATE_DEVICE from "../../api/mutation/devices/deactivateDevice";
import REMOVE_DEVICE from "../../api/mutation/devices/removeDevice";
import GET_DEVICES from "../../api/query/getDevices";
import ListHeader from "../../shared/elements/list-header/ListHeader";
import { useDialog } from "../../shared/helpers/useDialog";
import { useModal } from "../../shared/helpers/useModal";
import { useToast } from "../../shared/helpers/useToast";
import ListTitle from "./components/ListTitle";
import CreateDeviceModal from "./modals/createDeviceModal";
import UpdateDeviceModal from "./modals/updateDeviceModal";
import DevicesContainer from "./view/index";
import Loader from "../../shared/elements/loader/Loader";
import Empty from "../../shared/elements/empty-message/Empty";

const Wrapper = styled.div`
  position: relative;
`;
const DeviceWrapper = styled.div`
  max-width: 1152px;
  margin: 0 auto;
  padding: 46px 15px 0;
  ${media.md`
		padding: 98px 24px 0;
	`};
  ${media.lg`
		padding-top: 117px;
	`};
`;
export const ModalChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export type DeviceValues = {
  devices: {
    limit: number;
    offset: number;
    count: number;
    values: Device[];
  };
};

export type DeviceFields = {
  reference: string;
  mac: string;
  name: string;
};

const DevicePage = () => {

  const { dialog, showDialog, hideDialog } = useDialog(false);

  const { showToast, portalComponent: toast } = useToast();

  const duration = 3000;

  const [search, setSearch] = useState<string>("");

  const filter: Filter = {
    search,
    limit: 1000,
    offset: 0,
  };

  const { data, loading, fetchMore, refetch } = useQuery<GetDevicesQueryResult>(GET_DEVICES, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter,
    },
  });

  const [removeDeviceMutation] = useMutation<RemoveDeviceMutationResult, RemoveDeviceMutationVariables>(REMOVE_DEVICE);

  const refetchDevices = useCallback(() => refetch({ variables: { filter } }), [refetch, filter]);

  const handleRemoveDevice = useCallback(
    (id: number) => {
      return removeDeviceMutation({ variables: { id } })
        .then(async ({ data }: ExecutionResult<RemoveDeviceMutationResult>) => {
          if (data?.removeDevice) {
            showToast({
              appearance: {
                duration,
                color: "green",
                borderColor: "borderRedColor",
                titleColor: "darkRed",
                message: `Suppression réussie`,
              },
            });
            hideDialog();
            return refetchDevices();
          }
        })
        .catch((e) => {
          showToast({
            appearance: {
              duration,
              color: "lightRed",
              borderColor: "borderRedColor",
              titleColor: "darkRed",
              message: e.message,
            },
          });
          hideDialog();
        });
    },
    [showToast, removeDeviceMutation, hideDialog, refetchDevices]
  );

  const removeDeviceProcess = useCallback(
    async (id: number) => {
      showDialog({
        title: "Retrait du dispositif",
        extraField: `Êtes-vous certain de vouloir retirer l'appareil ?`,
        buttonText: "supprimer",
        onReject: () => hideDialog(),
        onApprove: () => handleRemoveDevice(id),
      });
    },
    [handleRemoveDevice, hideDialog, showDialog]
  );

  const increaseOffset = useCallback(() => {
    return fetchMore({
      variables: { pagination: { offset: data?.devices.values.length } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          devices: {
            ...prev.devices,
            values: [...prev.devices.values, ...fetchMoreResult.devices.values],
          },
        });
      },
    });
  }, [data, fetchMore]);

  const resetDeviceFields: DeviceFields = { reference: "", name: "", mac: "" };

  const [deviceFields, setDeviceFields] = useState<DeviceFields>({ ...resetDeviceFields });

  const [deviceId, setDeviceId] = useState<number>(0);

  const { visible: visibleCreateModal, showModal: showCreateModal, hideModal: hideCreateModal } = useModal();

  const createDeviceProcess = useCallback(() => showCreateModal(), [showCreateModal]);

  const { visible: visibleUpdateModal, showModal: showUpdateModal, hideModal: hideUpdateModal } = useModal();

  const updateDeviceProcess = useCallback(
    (id: number) => {
      setDeviceId(id);
      const device = data?.devices.values.find((d) => d.id === id && d);
      const fields: DeviceFields = device ? { reference: device.reference, name: device.name, mac: device.mac } : { ...resetDeviceFields };
      setDeviceFields({ ...fields });
      showUpdateModal();
    },
    [showUpdateModal, resetDeviceFields, data]
  );

  const { dialog: activateDialog, showDialog: showActivateDialog, hideDialog: hideActivateDialog } = useDialog(false);
  const { dialog: dectivateDialog, showDialog: showDeactivateDialog, hideDialog: hideDeactivateDialog } = useDialog(
    false
  );

  const [activateDeviceMutation] = useMutation<ActivateDeviceMutationResult, ActivateDeviceMutationVariables>(
    ACTIVATE_DEVICE
  );
  const [deactivateDeviceMutation] = useMutation<DeactivateDeviceMutationResult, DeactivateDeviceMutationVariables>(
    DEACTIVATE_DEVICE
  );
  const handleActivateDevice = useCallback(
    (id: number) => {
      return activateDeviceMutation({ variables: { id } })
        .then(async ({ data }: ExecutionResult<ActivateDeviceMutationResult>) => {
          if (data?.activateDevice) {
            showToast({
              appearance: {
                duration,
                color: "green",
                borderColor: "borderRedColor",
                titleColor: "darkRed",
                message: `Activation réussie.`,
              },
            });
            hideActivateDialog();
            return refetchDevices();
          }
        })
        .catch((e) => {
          showToast({
            appearance: {
              duration,
              color: "lightRed",
              borderColor: "borderRedColor",
              titleColor: "darkRed",
              message: e.message,
            },
          });
          hideDialog();
        });
    },
    [showToast, hideDialog, activateDeviceMutation, hideActivateDialog, refetchDevices]
  );

  const handleDeactivateDevice = useCallback(
    (id: number) => {
      return deactivateDeviceMutation({ variables: { id } })
        .then(async ({ data }: ExecutionResult<DeactivateDeviceMutationResult>) => {
          if (data?.deactivateDevice) {
            showToast({
              appearance: {
                duration,
                color: "green",
                borderColor: "borderRedColor",
                titleColor: "darkRed",
                message: `Désactivation réussie`,
              },
            });
            hideDeactivateDialog();
            return refetchDevices();
          }
        })
        .catch((e) => {
          showToast({
            appearance: {
              duration,
              color: "lightRed",
              borderColor: "borderRedColor",
              titleColor: "darkRed",
              message: e.message,
            },
          });
        });
    },
    [showToast, deactivateDeviceMutation, hideDeactivateDialog, refetchDevices]
  );

  const activateDeviceProcess = useCallback(
    async (id: number, deviceName: string) => {
      showActivateDialog({
        title: `Statut de réserve`,
        extraField: `Êtes-vous sûr de vouloir activer le iPad ${deviceName}?`,
        buttonText: "activé",
        onReject: () => hideActivateDialog(),
        onApprove: () => handleActivateDevice(id),
      });
    },
    [handleActivateDevice, hideActivateDialog, showActivateDialog]
  );

  const deactivateDeviceProcess = useCallback(
    async (id: number, deviceName: string) => {
      showDeactivateDialog({
        title: `Statut de réserve`,
        extraField: `Êtes-vous sûr de vouloir désactiver le iPad ${deviceName}?`,
        buttonText: "désactivé",
        onReject: () => hideDeactivateDialog(),
        onApprove: () => handleDeactivateDevice(id),
      });
    },
    [handleDeactivateDevice, hideDeactivateDialog, showDeactivateDialog]
  );
  const devicesData: DeviceValues = data ? data : { devices: { count: 0, limit: 0, offset: 0, values: [] } };
	const isNoResult: boolean = !loading && devicesData.devices.values.length === 0;
  return (
    <Wrapper>
      {toast}
      {dialog}
      {activateDialog}
      {dectivateDialog}
      <CreateDeviceModal
        visible={visibleCreateModal}
        title="Créer un iPad"
        onClose={hideCreateModal}
        refetch={refetchDevices}
        initialValue={deviceFields}
      />
      <UpdateDeviceModal
        visible={visibleUpdateModal}
        title="Modifier un iPad"
        onClose={hideUpdateModal}
        refetch={refetchDevices}
        initialValue={deviceFields}
        deviceId={deviceId}
      />
      <DeviceWrapper>
        <ListHeader
          title="Liste des appareils"
          button="Créer un iPad"
          onClick={createDeviceProcess}
          onSearch={setSearch}
          search={search}
        />
        <ListTitle />

        {loading ? <Loader loadedItemName="l'appareil" /> : null}
        {isNoResult ? <Empty entityName="appareils" /> : (
          <DevicesContainer
            devicesData={devicesData}
            increaseOffset={increaseOffset}
            update={updateDeviceProcess}
            remove={removeDeviceProcess}
            activate={activateDeviceProcess}
            deactivate={deactivateDeviceProcess}
					/>)}
      </DeviceWrapper>
    </Wrapper>
  );
};

export default DevicePage;
