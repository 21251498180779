import { css } from "styled-components";
import { StyledThemePropsType } from "../../config/theme/index";
import {
  ButtonTextStyle,
} from "../typography/typography";

const DefaultButton = css`
  ${ButtonTextStyle};
  width: 88px;
  height: 38px;
  border-radius: 10px;
  border: none;
  color: ${(props: StyledThemePropsType) => props.theme.colors.white};
  cursor: pointer;
  outline: none;
`;

const defaultStyles = {
  DefaultButton
};

export default defaultStyles;
