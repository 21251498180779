import gql from "graphql-tag";

const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      id
      firstName
      lastName
      username
      role
    }
  }
`;

export default UPDATE_USER;
