import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/index";
import { InputTextStyle } from "../../typography/typography";


type DefaultSelectProps = {
  label?: string;
  value: string;
  options?: any;
  onChange(event: ChangeEvent<HTMLSelectElement>): void;
};

const DefaultSelect = styled.select<DefaultSelectProps>`
  ${InputTextStyle};
  width: 100%;
  height: 38px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.white};
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.blueGray};
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 11px;
  outline: none;
  ::-webkit-input-placeholder {
    color: ${(props: StyledThemePropsType) => props.theme.colors.inputText};
  }
  margin-top: 10px;
`;

const Label = styled.label`
  ${InputTextStyle};
  margin-bottom: 10px;
`;

function Select({ value, options, label, onChange }: DefaultSelectProps) {
  return (
    <>
      {label ? <Label>{label}</Label> : null}
      <DefaultSelect
         value={value}
         onChange={onChange}
      >
         {options.map((option, index) => ( <option key={index} value={option.value}>{option.label}</option> ))}
      </DefaultSelect>
    </>
  );
}

export default Select;
