import gql from "graphql-tag";

const GET_ME = gql`
	query getMe{
		me{
			id
			firstName
			lastName
			username
			role
		}
	}
`;

export default GET_ME;
