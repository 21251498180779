import React from "react";
import styled from "styled-components";
import { H3Style } from "../../typography/typography";

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderText = styled.h3`
  ${H3Style};
`;

type LoaderProps = {
  loadedItemName: string;
};

function Loader({ loadedItemName }: LoaderProps) {
  return (
    <LoaderWrapper>
        <LoaderText>Chargement de {loadedItemName}</LoaderText>
    </LoaderWrapper>
  );
}

export default Loader;
