import React, { FC, useCallback, useContext } from "react";
import { Container, Row } from "styled-bootstrap-grid";
import { DeviceStatus } from "../../../api/interface/devices";
import ActionButton from "../../../shared/elements/action-button/ActionButton";
import { UserContext } from "../../../store/index";
import HiddenCardItem from "../components/deviceContainer/HiddenCardItem";
import { DeviceValues } from "../index";
import {
  ButtonProps,
  Card,
  CardBody,
  CardItem,
  DefaultButton,
  DeviceCardWrapper,
  DeviceText,
  Element,
  ElementAction,
  ElementSwitch,
  SwitchButton,
  Wrapper,
} from "./elements";

type DevicesContainerProps = {
  devicesData: DeviceValues;
  increaseOffset(): void;
  update(id: number): void;
  remove(id: number): void;
  activate(id: number, deviceName: string): void;
  deactivate(id: number, deviceName: string): void;
};

const DevicesContainer: FC<DevicesContainerProps> = ({
  devicesData,
  remove,
  update,
  activate,
  deactivate,
}) => {

  const removeDevice = useCallback((id: number) => () => remove(id), [remove]);

  const updateDevice = useCallback((id: number) => () => update(id), [update]);

  const activateAction = useCallback(
    (id: number, deviceName: string) => {
      activate(id, deviceName);
    },
    [activate]
  );

  const deactivateAction = useCallback(
    (id: number, deviceName: string) => {
      deactivate(id, deviceName);
    },
    [deactivate]
  );

  const buttons: ButtonProps[] = [
    {
      name: "activé",
      activeColor: "green",
      type: DeviceStatus.Active,
      onClick: () => ({}),
      isActive: false,
    },
    {
      name: "désactivé",
      activeColor: "orange",
      type: DeviceStatus.Inactive,
      onClick: () => ({}),
      isActive: false,
    },
  ];

  const { isAdmin } = useContext(UserContext);

  return (
    <Wrapper>
      <DeviceCardWrapper>
        <Container fluid>
          <Row>
            {devicesData.devices.values.map((device, index) => {
              return (
                <Card xl={12} lg={12} md={6} sm={12} key={index}>
                  <CardBody>
                    <HiddenCardItem />

                    <CardItem lg={12} md={7} col={7}>
                      <Element col={12} lg={2}>
                        <DeviceText>{device.reference}</DeviceText>
                      </Element>

											<Element col={12} lg={2}>
                        <DeviceText>{device.name}</DeviceText>
                      </Element>

                      <Element col={12} lg={2}>
                        <DeviceText>{device.mac}</DeviceText>
                      </Element>

                      <ElementSwitch col={12} lg={3}>
                        <SwitchButton>
                          {buttons.map((button, index) => {
                            button.onClick =
                              button.type === DeviceStatus.Active 
                                ? () => activateAction(device.id, device.reference)
                                : () => deactivateAction(device.id, device.reference);

                            return (
                                <DefaultButton
                                  key={index}
                                  onClick={button.onClick}
                                  activeColor={button.activeColor}
                                  isActive={device.status === button.type}
                                >
                                  {button.name}
                                </DefaultButton>
                              );
                          })}
                        </SwitchButton>
                      </ElementSwitch>
                      
                      {isAdmin ?
                        <ElementAction col={12} lg={3}>
                          <ActionButton name="Modifier" onClick={updateDevice(device.id)} />
                          <ActionButton name="Supprimer" onClick={removeDevice(device.id)} />
                        </ElementAction>
                        : null
                      }
                    </CardItem>
                  </CardBody>
                </Card>
              );
            })}
          </Row>
        </Container>
      </DeviceCardWrapper>
    </Wrapper>
  );
};

export default DevicesContainer;
