export enum UserRoles {
  USER = "USER",
  ADMIN = "ADMIN",
}

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  role: UserRoles;
}

export type GetUsersQueryResult = {
  users: {
    limit: number;
    offset: number;
    count: number;
    values: User[]
  };
}

export type Filter = {
  limit: number;
  offset: number | undefined;
  search: string;
}

export type RemoveUserMutationResult = {
  removeUser: User;
}

export type RemoveUserMutationVariables = {
  id: number;
}

export type CreateUserMutationResult = {
  createUser: User;
}

export type CreateUserMutationVariables = {
  input: {
    firstName: string;
    lastName: string;
    username: string;
    password: string;
    role: UserRoles;
  };
}

export type UpdateUserMutationResult = {
  updateUser: User;
}

export type UpdateUserMutationVariables = {
  id: number;
  input: {
    firstName: string;
    lastName: string;
    username: string;
    password: string;
    role: UserRoles;
  };
}
