import { useCallback, useState } from "react";

type UseModalHookReturnType = {
  visible: boolean,
  showModal: () => void,
  hideModal: () => void,
};

export function useModal(): UseModalHookReturnType {
	const [visible, setVisible] = useState<boolean>(false);
	const showModal = useCallback(() => {
		setVisible(true);
	}, []);
	const hideModal = useCallback(() => {
		setVisible(false);
	}, []);

	return { visible, showModal, hideModal };
}
