import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../../../config/theme/index";
import { AUTH_TOKEN } from "../../../../../routing/variables";
import { UserContext } from "../../../../../store/index";
import LogOut from "../../../../icons/log-out.svg";
import { H6Style } from "../../../../typography/typography";

const LogOutWrapper = styled.div`
  display: flex;
  margin: 15px 0 0 0;
  ${media.lg`
		margin: 0 0 0 80px;
	`};
`;
const LogoutText = styled.h5`
  ${H6Style};
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  margin: 0 0 0 12px;
  cursor: pointer;
  color: ${(props: StyledThemePropsType) => props.theme.colors.blue};
`;
const Image = styled.img`
  cursor: pointer;
`;

type LogOutSectionProps = {
  closeMenu(): void;
};

const LogOutSection = ({ closeMenu }: LogOutSectionProps) => {
  const { push } = useHistory();

  const { saveUserInfo } = useContext(UserContext);

  const logout = useCallback(() => {
    closeMenu();
    localStorage.removeItem(AUTH_TOKEN);
    saveUserInfo({ isAuthorized: false, userRole: null, isAdmin: false });
    push("/login");
  }, [push, closeMenu, saveUserInfo]);

  return (
    <LogOutWrapper onClick={logout}>
      <Image src={LogOut} alt="log out" />
      <LogoutText>Déconnexion</LogoutText>
    </LogOutWrapper>
  );
};

export default LogOutSection;
