import React, { FunctionComponent, useCallback, useState } from "react";
import { UserRoles } from "../api/interface/me";
import { AUTH_TOKEN } from "../routing/variables";

export type UserDate = {
  isAuthorized: boolean;
  userRole: UserRoles | null;
  isAdmin: boolean;
};

export const initialUserData: UserDate = {
  isAuthorized: false,
  userRole: UserRoles.ADMIN,
  isAdmin: false,
};

type UserContext = UserDate & {
  saveUserInfo(modifiedData: UserDate): void;
};

export const UserContext = React.createContext<UserContext>({
  ...initialUserData,
  saveUserInfo(modifiedData: UserDate): UserDate {
    return modifiedData;
  },
});

export const UserContextProvider = UserContext.Provider;

const Store: FunctionComponent = (props) => {
  const token = localStorage.getItem(AUTH_TOKEN);

  const [userData, setUserData] = useState<UserDate>({ ...initialUserData, isAuthorized: !!(token) });

  const saveUserInfo = useCallback((modifiedData: UserDate) => setUserData({ ...modifiedData }), []);

  return (
    <UserContextProvider value={{ ...userData, saveUserInfo }}>
      {props.children}
    </UserContextProvider>
  );
};

export default Store;
