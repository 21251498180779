import styled, { css } from "styled-components";
import {
  StyledThemePropsType,
  TypographyKeyType,
} from "../../config/theme/index";

const buildTypographyStyle = (typographyKey: TypographyKeyType) => {
  return css`
    font-weight: ${(props: StyledThemePropsType) =>
      props.theme.typography[typographyKey].fontWeight};
    font-size: ${(props: StyledThemePropsType) =>
      props.theme.typography[typographyKey].fontSize};
    line-height: ${(props: StyledThemePropsType) =>
      props.theme.typography[typographyKey].lineHeight};
    color: ${(props: StyledThemePropsType) =>
      props.theme.typography[typographyKey].color};
  `;
};

export const H2Style = buildTypographyStyle("h2");
export const H3Style = buildTypographyStyle("h3");
export const H4Style = buildTypographyStyle("h4");
export const H5Style = buildTypographyStyle("h5");
export const H6Style = buildTypographyStyle("h6");
export const ButtonTextStyle = buildTypographyStyle("buttonText");
export const InputTextStyle = buildTypographyStyle("inputText");

export const H2 = styled.div`
  ${H2Style};
`;
export const H3 = styled.div`
  ${H3Style};
`;
export const H4 = styled.div`
  ${H4Style};
`;
export const H5 = styled.div`
  ${H5Style};
`;
export const H6 = styled.div`
  ${H6Style};
`;
export const ButtonText = styled.div`
	${ButtonTextStyle};
`;
export const InputText = styled.div`
	${InputTextStyle};
`;

