import React, { ChangeEvent, useCallback } from "react";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../../../config/theme/index";
import Icon from "../../../../icons/search.svg";
import { InputTextStyle } from "../../../../typography/typography";

type DefaultInputProps = {
  placeholder: string;
  value: string;
};

type SearchInputProps = {
  placeholder: string;
  onSearch(val: string): void;
  value: string;
}

const SearchInputWrapper = styled.div`
  width: 200px;
  height: 38px;
  margin: 25px 0 20px;
  position: relative;
  ${media.md`
    margin: 0 0 0 auto;
    flex-direction: row;
    align-items: center;
  `};
`;
const Input = styled.input<DefaultInputProps>`
	width: 100%;
	height: 38px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.white};
  border: 1px solid
    ${(props: StyledThemePropsType) => props.theme.colors.blueGray};
  box-sizing: border-box;
  border-radius: 10px;
  ${InputTextStyle};
  padding-left: 13px;
  outline: none;

  ::-webkit-input-placeholder {
    color: ${(props: StyledThemePropsType) => props.theme.colors.inputText};
  }
`;
const Image = styled.img`
  position: absolute;
  right: 16px;
  top: 10px;
`;

function SearchInput(props: SearchInputProps) {
  const { placeholder, onSearch, value } = props;

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    onSearch(val);
  }, [onSearch]);

  return (
    <SearchInputWrapper>
      <Input onChange={onChange} value={value} placeholder={placeholder} />
      <Image src={Icon} />
    </SearchInputWrapper>
  );
}

export default SearchInput;
