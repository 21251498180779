import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { AUTH_TOKEN, clearStore } from "../../routing/variables";

const cache = new InMemoryCache();

// const uri = process.env.REACT_APP_BACKEND_API

const link = new HttpLink({ uri: "https://zenconnect-production.herokuapp.com/graphql" });

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem(AUTH_TOKEN);
	return {
		headers: {
			...headers,
			Authorization: token ? token : "",
		},
	};
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.map((err) => {
			switch (err.message) {
				case "Non autorisé.":
					return clearStore();
				case "Autorisation refusée.":
					clearStore();					
					return window.location.replace("/login");
				default:
					return null;
			}
		})
	}

	if (networkError) console.log(`[Network error]: ${networkError}`);
})

const client = new ApolloClient({
	cache,
	name: "ZenConnect",
	// @ts-ignore
	link: ApolloLink.from([authLink, onErrorLink, link]),
});

export default client;
