import { useQuery } from "@apollo/client";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { GetMeQueryResult } from "../../../api/interface/me";
import { UserRoles } from "../../../api/interface/users";
import GET_ME from "../../../api/query/getMe";
import { StyledThemePropsType } from "../../../config/theme/index";
import { UserContext } from "../../../store/index";
import { H3Style } from "../../typography/typography";
import MenuButton from "./components/buttonSection/MenuButton";
import HeaderLogo from "./components/logoSection/HeaderLogo";
import LogOutSection from "./components/logoutSection/LogOutSection";
import UserSection from "./components/userSection/UserSection";

type HeaderWrapperProps = {
  openFlag: boolean;
  isAuthorized: boolean;
};
const HeaderWrapper = styled.div<HeaderWrapperProps>`
  position: ${(props: HeaderWrapperProps & StyledThemePropsType) => (props.isAuthorized ? "relative" : "absolute")};
  top: 0;
  left: 0;
  z-index: ${(props: HeaderWrapperProps & StyledThemePropsType) => (props.isAuthorized ? "0" : "2")};
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: ${(props: HeaderWrapperProps) => (props.openFlag ? "302px" : "73px")};
  background-color: ${(props: HeaderWrapperProps & StyledThemePropsType) =>
    props.isAuthorized ? props.theme.colors.white : props.theme.colors.backgroundColor};
  box-shadow: ${(props: HeaderWrapperProps & StyledThemePropsType) =>
    props.isAuthorized ? "0 4px 11px rgba(22, 152, 175, 0.09)" : "none"};

  ${media.lg`
		padding-left: 111px;
		align-items: center;
	`};
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;

  ${media.lg`
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		width: 100%;
		padding: 0 125px 0 132px;
		margin-bottom: 0;
	`};
`;
const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;

  ${media.lg`
		margin-left: 52px;
		padding: 0;
		flex-direction: row;
	`};
`;

type TextLinkProps = {
  decoration: boolean;
};

const DefaultLink = styled(Link)`
  text-decoration: none;
`;
const TextLink = styled.h3<TextLinkProps>`
  ${H3Style};
  display: flex;
  margin-top: 15px;
  cursor: pointer;
  color: ${(props: StyledThemePropsType) => props.theme.colors.blue};
  text-decoration: ${(props: TextLinkProps) => (props.decoration ? "underline" : "none")};

  ${media.lg`
		margin: 0 20px 0 0;
	`};
`;
const DeviceTextLink = styled(TextLink)`
  text-decoration: ${(props: TextLinkProps) => (props.decoration ? "underline" : "none")};
`;
const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 30px;
  width: 100%;
  margin-top: 15px;
  border-top: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.gray};

  ${media.lg`
		align-items: center;
		justify-content: flex-end;
		flex-direction: row;
		border: none;
		padding: 0;
		margin-top: 0;
	`};
`;

const MainHeader: FC = () => {
  const { push } = useHistory();

  const { pathname } = useLocation();

  const { isAuthorized, isAdmin, saveUserInfo } = useContext(UserContext);

  const { data, loading } = useQuery<GetMeQueryResult>(GET_ME);

  useEffect(() => {
    if (!loading && data) {
      saveUserInfo({
        userRole: data.me.role,
        isAuthorized: true,
        isAdmin: data.me.role === UserRoles.ADMIN,
      });
    }
  }, [loading, data, saveUserInfo]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setInitialWindowSize(window.innerWidth);
    });
    window.removeEventListener("resize", () => {
      setInitialWindowSize(window.innerWidth);
    });
  }, []);

  const [initialWindowSize, setInitialWindowSize] = useState<number>(window.innerWidth);

  const isDesktopSize = initialWindowSize > 1151;

  const [open, setOpen] = useState<boolean>(isDesktopSize);

  const [openFlag, setOpenFlag] = useState<boolean>(false);

  const changeVisibility = useCallback(() => {
    if (!isDesktopSize) {
      setOpen(!open);
      setOpenFlag(!openFlag);
    }
  }, [open, openFlag, isDesktopSize]);

  const isPathDevices: boolean = pathname === "/devices";
  const isPathUsers: boolean = pathname === "/users";
  const isPathLogin: boolean = pathname === "/login";
	const isPathStart: boolean = pathname === "/";

	const showMenu: boolean = useMemo(()=>isAuthorized && !isPathLogin, [isAuthorized, isPathLogin])
	
	const [currentPath, setCurrentPath] = useState(pathname);
	useEffect(() => {
		if (currentPath !== pathname && !isDesktopSize) {
			setOpen(false);
			setOpenFlag(false);
			setCurrentPath(pathname);
		}
	}, [currentPath, pathname, isDesktopSize]);

  useEffect(() => {


    if (!isAdmin && isPathUsers && !loading && data?.me.role !== UserRoles.ADMIN) {
      push("/devices");
    }

    if (!isAuthorized) {
      push("/login");
		}
		if (isAuthorized && (isPathStart || isPathLogin)) {
      push("/devices");
    }
  }, [push, isAdmin, isPathUsers, isPathLogin, isPathStart, loading, isAuthorized, data]);

	useEffect(() => setOpen(isDesktopSize), [isDesktopSize]);
	
  useEffect(() => {
    if (isDesktopSize) {
      setOpenFlag(false);
    }
	}, [initialWindowSize, isDesktopSize]);
	
	useEffect(() => {
    if (isDesktopSize) {
      setOpenFlag(false);
    }
  }, [initialWindowSize, isDesktopSize]);

  if (loading) return null;

  const firstName = data ? data.me.firstName : "";
  const lastName = data ? data.me.lastName : "";

  return (
    <HeaderWrapper isAuthorized={isAuthorized} openFlag={openFlag}>
      <HeaderLogo />
      {showMenu ? (
        <>
          <MenuButton onClick={changeVisibility} />
          {open && (
            <HeaderContainer>
              <LinkWrapper>
                {isAdmin ? (
                  <DefaultLink to="/users">
                    <TextLink decoration={isPathUsers}>personnel</TextLink>
                  </DefaultLink>
                ) : null}
                <DefaultLink to="/devices">
                  <DeviceTextLink decoration={isPathDevices}>appareils</DeviceTextLink>
                </DefaultLink>
              </LinkWrapper>
              <UserWrapper>
                <UserSection firstName={firstName} lastName={lastName} />
                <LogOutSection closeMenu={changeVisibility} />
              </UserWrapper>
            </HeaderContainer>
          )}
        </>
      ) : null}
    </HeaderWrapper>
  );
};

export default MainHeader;
