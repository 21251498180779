import React, { useContext } from "react";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { UserContext } from "../../../store/index";
import { H2Style } from "../../typography/typography";
import AddButton from "./components/addButton/AddButton";
import SearchInput from "./components/search/SearchInput";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.md`
		flex-direction: row;
		align-items: center;
		margin-bottom: 23px;
	`};
`;
const Title = styled.h2`
  ${H2Style};
  letter-spacing: 1.2px;
  margin: 0 0 23px 0;
  ${media.md`
    margin: 0 30px 0 0;
  `};
  ${media.lg`
		margin-right: 50px;
	`};
`;

type ListHeaderProps = {
  title: string;
  button: string;
  onClick(id: number): void;
  onSearch(val: string): void;
  search: string;
};

function ListHeader(props: ListHeaderProps) {
  const { button, onSearch, onClick, search, title } = props;

  const { isAdmin } = useContext(UserContext);

  return (
    <ListWrapper>
      <Title>{title}</Title>
      {isAdmin ?
        <AddButton button={button} onClick={onClick} />
         : null
      }
      <SearchInput placeholder="Recherche" onSearch={onSearch} value={search} />
    </ListWrapper>
  );
}

export default ListHeader;
