import React, { FC, useCallback } from "react";
import { Col, Container, media, Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../config/theme/index";
import ActionButton from "../../shared/elements/action-button/ActionButton";
import { H3Style } from "../../shared/typography/typography";
import { UserValues } from "./index";
import HiddenCardItem from "./components/userContainer/HiddenCardItem";

const Wrapper = styled.div`
  display: flex;
`;
const UserCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${media.md`
		flex-direction: row;
	`};
  ${media.lg`
		flex-direction: column;
	`};
`;
const Card = styled(Col)`
  display: flex;
  height: 318px;
  margin-bottom: 4px;
  justify-content: center;
  ${media.md`
	margin-bottom: 30px;
`};
  ${media.lg`
		height: 68px;
		margin-bottom: 4px;
	`};
`;
const CardBody = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 345px;
  border-radius: 17px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.white};
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.borderColor};
  box-sizing: border-box;
  padding: 24px 17px;

  ${media.lg`
		padding: 0 0 0 17px;
		width: 100%;
	`};
`;
const CardItem = styled(Col)`
  flex-direction: column;

  ${media.lg`
	display: flex;
	flex-direction: row;
`};
`;
const Element = styled(Col)`
  margin-bottom: 29px;
  display: flex;
  ${media.lg`
	margin-bottom: 40px;
	`};
  ${media.lg`
	margin-bottom: 0;
	align-items: center;
	`};
`;
const ElementAction = styled(Element)`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  ${media.lg`
		flex-direction: row;
		margin: 0;
	`};
`;
const UserText = styled.h3`
  ${H3Style};
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
`;

type UsersContainerProps = {
  usersData: UserValues;
  increaseOffset(): void;
  remove(id: number, username: string): void;
  update(id: number): void;
};

const UsersContainer: FC<UsersContainerProps> = ({ usersData, remove, update }) => {
  const removeUser = useCallback((id: number, username: string) => () => remove(id, username), [remove]);

  const updateUser = useCallback((id: number) => () => update(id), [update]);

  return (
    <Wrapper>
      <UserCardWrapper>
        <Container fluid>
          <Row>
            {usersData?.users.values.map((user, index) => {
              return (
                <Card xl={12} lg={12} md={6} sm={12} key={index}>
                  <CardBody>
                    <HiddenCardItem />
                    <CardItem lg={12} md={7} col={7}>
                      <Element col={12} lg={3}>
                        <UserText>{user.username}</UserText>
                      </Element>
                      <Element col={12} lg={3}>
                        <UserText>{user.firstName}</UserText>
                      </Element>
                      <Element col={12} lg={3}>
                        <UserText>{user.lastName}</UserText>
                      </Element>
                      <ElementAction col={12} lg={3}>
                        <ActionButton name="Modifier" onClick={updateUser(user.id)} />
                        <ActionButton
                          name="Supprimer"
                          onClick={removeUser(user.id, `${user.firstName} ${user.lastName}`)}
                        />
                      </ElementAction>
                    </CardItem>
                  </CardBody>
                </Card>
              );
            })}
          </Row>
        </Container>
      </UserCardWrapper>
    </Wrapper>
  );
};

export default UsersContainer;
