import React from "react";
import styled from "styled-components";
import { StyledThemePropsType } from "../../config/theme/index";
import LoginContainer from "./view";

const LoginWrapper = styled.div`
  position: relative;
	height: 100vh;
	background-color: ${(props: StyledThemePropsType) => props.theme.colors.backgroundColor};
`;

const LoginPage = () => {
  return (
    <LoginWrapper>
      <LoginContainer />
    </LoginWrapper>
  );
};

export default LoginPage;
