import { useMutation } from "@apollo/client";
import { ExecutionResult } from "graphql";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { UpdateDeviceMutationResult, UpdateDeviceMutationVariables } from "../../../api/interface/devices";
import UPDATE_DEVICE from "../../../api/mutation/devices/updateDevice";
import Input from "../../../shared/elements/input/Input";
import ModalComponent from "../../../shared/elements/modal/modal";
import { isMacAddress } from "../../../shared/helpers/isMacAddress";
import { useToast } from "../../../shared/helpers/useToast";
import { DeviceFields, ModalChildrenWrapper } from "../index";
import { UpdateDeviceSchema } from "../validation";

export type DeviceModalProps = {
  visible: boolean;
  title: string;
  onClose(): void;
  refetch(): void;
  initialValue: DeviceFields;
  deviceId: number;
};

const ModalInputWrapper = styled.div`
  width: 323px;
  margin-bottom: 30px;
  ${media.md`
		width: 411px;
	`};
`;

const ModalInput = styled(Input)`
  margin-top: 10px;
`;

const UpdateDeviceModal: FC<DeviceModalProps> = ({ visible, title, refetch, onClose, initialValue, deviceId }) => {
  const [deviceFields, setDeviceFields] = useState<DeviceFields>({ ...initialValue });

  useEffect(() => setDeviceFields({ ...initialValue }), [initialValue]);

  const inputOnChange = useCallback(
    (field) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      return setDeviceFields((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [],
  );

  const resetDeviceFields: DeviceFields = { reference: "asd", name: "asd", mac: "asd" };

  const resetDeviceFieldsAction = useCallback(() => setDeviceFields({ ...resetDeviceFields }), [resetDeviceFields]);

  const onCloseModal = useCallback(() => {
    onClose();
    resetDeviceFieldsAction();
  }, [onClose, resetDeviceFieldsAction]);

  const { showToast, portalComponent: toast } = useToast();

  const [updateDeviceMutation] = useMutation<UpdateDeviceMutationResult, UpdateDeviceMutationVariables>(UPDATE_DEVICE);

  const enableButton = useMemo(() => isMacAddress(deviceFields.mac) && !!deviceFields.name && !!deviceFields.reference, [deviceFields]);

  const handleCreateDevice = useCallback(async () => {
		const validationPassed = await UpdateDeviceSchema.validate({ ...deviceFields }, { abortEarly: false })
    .catch((e) => {
      showToast({
        appearance: {
          duration: 3000,
          color: "lightRed",
          borderColor: "borderRedColor",
          titleColor: "darkRed",
          message: e.inner[0].message,
        },
      });
		});
		
    if (enableButton && validationPassed) {
      return updateDeviceMutation({ 
        variables: { 
          id: deviceId, 
          input: {
            reference: deviceFields.reference,
            name: deviceFields.name,
          }
        } 
      })
      .then(async ({ data }: ExecutionResult<UpdateDeviceMutationResult>) => {
        if (data?.updateDevice) {
          showToast({
            appearance: {
              duration: 3000,
              color: "green",
              borderColor: "borderRedColor",
              titleColor: "darkRed",
              message: `Dispositif créé avec succès ${data.updateDevice.reference}.`,
            },
          });
          resetDeviceFieldsAction();
          onClose();
          return refetch();
        }
      })
      .catch((error) => {
        showToast({
          appearance: {
            duration: 3000,
            color: "lightRed",
            borderColor: "borderRedColor",
            titleColor: "darkRed",
            message: error.message,
          },
        });
      });
    } else {
      if (!isMacAddress(deviceFields.mac)) {
        showToast({
          appearance: {
            duration: 3000,
            color: "lightRed",
            borderColor: "borderRedColor",
            titleColor: "darkRed",
            message: `Incorrect MAC Address.`,
          },
        });
      }
    }
  }, [
    showToast,
    refetch,
    onClose,
    updateDeviceMutation,
    deviceFields,
    deviceId,
    resetDeviceFieldsAction,
    enableButton,
  ]);

  return (
    <>
      {toast}
      <ModalComponent
        title={title}
				visible={visible}
				buttonText = "modifier"
        onApprove={handleCreateDevice}
        onReject={onCloseModal}
        disabled={!enableButton}
      >
        <ModalChildrenWrapper>
          <ModalInputWrapper>
            <ModalInput
              placeholder="Numéro iPad"
              value={deviceFields.reference}
              onChange={inputOnChange("reference")}
              label="Numéro iPad"
            />
          </ModalInputWrapper>
					<ModalInputWrapper>
            <ModalInput
              placeholder="Nom d'iPad"
              value={deviceFields.name}
              onChange={inputOnChange("name")}
              label="Nom d'iPad"
            />
          </ModalInputWrapper>
          <ModalInputWrapper>
            <ModalInput
              placeholder="Adresse MAC"
              value={deviceFields.mac}
              onChange={inputOnChange("mac")}
              label="Adresse MAC"
              disabled
            />
          </ModalInputWrapper>
        </ModalChildrenWrapper>
      </ModalComponent>
    </>
  );
};

export default UpdateDeviceModal;
