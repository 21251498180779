import React from "react";
import styled, { css } from "styled-components";
import defaultStyles from "../../styles/styles";
import { StyledThemePropsType } from "../../../config/theme/index";

type CustomButtonProps = {
  flagColor?: string;
  disabled?: boolean;
};

const CustomButton = styled.button<CustomButtonProps>`
  ${defaultStyles.DefaultButton};
  ${(props: CustomButtonProps & StyledThemePropsType) => {
    switch (props.flagColor) {
      case "connexion":
        return css`
          background-color: ${(props: CustomButtonProps & StyledThemePropsType) =>
            props.disabled ? props.theme.colors.blueGray : props.theme.colors.blue};
          pointer-events: ${(props: CustomButtonProps & StyledThemePropsType) => (props.disabled ? "none" : "all")};
        `;
				case "modifier":
        return css`
          background-color: ${(props: CustomButtonProps & StyledThemePropsType) =>
            props.disabled ? props.theme.colors.blueGray : props.theme.colors.blue};
          pointer-events: ${(props: CustomButtonProps & StyledThemePropsType) => (props.disabled ? "none" : "all")};
        `;
				case "créer":
        return css`
          background-color: ${(props: CustomButtonProps & StyledThemePropsType) =>
            props.disabled ? props.theme.colors.blueGray : props.theme.colors.blue};
          pointer-events: ${(props: CustomButtonProps & StyledThemePropsType) => (props.disabled ? "none" : "all")};
        `;
      case "activé":
        return css`
          background-color: ${(props: StyledThemePropsType) => props.theme.colors.green};
        `;
      case "supprimer":
        return css`
          background-color: ${(props: StyledThemePropsType) => props.theme.colors.red};
        `;
      case "désactivé":
        return css`
          background-color: ${(props: StyledThemePropsType) => props.theme.colors.orange};
        `;
      case "annuler":
        return css`
          background-color: ${(props: StyledThemePropsType) => props.theme.colors.blueGray};
        `;
      default:
        return css`
          background-color: ${(props: StyledThemePropsType) => props.theme.colors.blueGray};
        `;
    }
  }};
`;

type ButtonComponentProps = {
  name: string;
  onClick(): void;
  disabled?: boolean;
}

function ButtonComponent({ name, disabled, onClick } : ButtonComponentProps) {
  return (
    <CustomButton onClick={onClick} flagColor={name} disabled={disabled}>
      {name}
    </CustomButton>
  );
}

export default ButtonComponent;
