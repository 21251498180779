const colors = {
	blue: '#1698AF',
	gray: '#EAEAEA',
	textGrayColor: '#868E96',
	borderColor: '#DFF0F0',
	backgroundColor: '#F7FCFC',
	white: '#FFFFFF',
	red: '#D72E3D',
	almostBlack: '#2E3338',
	orange: '#FFC107',
	blueGray: '#CED4DA',
	inputText: '#BDBDBD',
	borderRedColor: '#F5C6CB',
	green: '#57BF80',
	pink: '#D72E3D',
	darkGray: '#828282',
	lightRed: '#FCE2E4',
	darkRed: '#721C24'
}

const typography = {
	h2: {
		fontSize: '32px',
		lineHeight: '24px',
		fontWeight: '500',
		color: colors.almostBlack
	},
	h3: {
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: 'normal',
		color: colors.almostBlack
	},
	h4: {
		fontSize: '20px',
		lineHeight: '24px',
		fontWeight: '500',
		color: colors.almostBlack
	},
	h5: {
		fontSize: '14px',
		lineHeight: '14px',
		fontWeight: '500',
		color: colors.almostBlack
	},
	h6: {
		fontSize: '12px',
		lineHeight: '16px',
		fontWeight: '500',
		color: colors.almostBlack
	},
	inputText: {
		fontSize: '16px',
		lineHeight: '12px',
		fontWeight: '400',
		color: colors.almostBlack
	},
	buttonText: {
		fontSize: '14px',
		lineHeight: '14px',
		fontWeight: '400',
		color: colors.almostBlack
	}
}

const defaultTheme = {
	colors,
	typography
};

export type ThemeType = typeof defaultTheme;
export type StyledThemePropsType = { theme: ThemeType };
export type TypographyKeyType = keyof typeof defaultTheme.typography;
export type DefinedColors = keyof typeof defaultTheme.colors;

export default defaultTheme;
